import React, { useEffect, useState } from 'react'
import serviceImage1 from "../../images/service-icon-01.png"
import serviceImage2 from "../../images/service-icon-02.png"
import serviceImage3 from "../../images/service-icon-03.png"
import { QRCode } from 'antd';
import OwlCarousel from 'react-owl-carousel';
import axios from 'axios';
import "../../css/owl.css"
import "../../css/global.css"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { MapComp } from '../../components/MapComp'
import { IoGlobeOutline, IoHomeOutline, IoLibraryOutline, IoMailOutline, IoQrCodeOutline } from "react-icons/io5";
import { FaBookOpenReader, FaCalendarWeek } from "react-icons/fa6";
import { FloatButton } from 'antd';
import BannerVideo from '../../components/BannerVideo'
import logoQR from "../../images/ni.png"
import "./Beranda.css"
import AgendaBeranda from './AgendaBeranda'
import {List } from 'antd';
import { MdOutlineAppRegistration } from 'react-icons/md'
import BaseUrl from '../../apiConfig';
import { useNavigate } from 'react-router-dom';



const datacontact = [
    {
        title: 'Alamat',
        icon: <IoHomeOutline />,
        desc: ' Jl. Jatibarang - Sitanggal Km 7, Dukuh Kedawon - Larangan - Brebes'
    },

    {
        title: 'Email',
        icon: <IoMailOutline />,
        desc: ' ppmtqni@gmail.com'
    },
    {
        title: 'Website',
        icon: <IoGlobeOutline />,
        desc: 'www.ppmtqni-brebes.ponpes.id'
    },
];


const Beranda = () => {
    const [berita, setBerita] = useState([]);
    const [agenda, setAgenda] = useState([]);
    
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BaseUrl}api/information/latest`);
                const response2 = await axios.get(`${BaseUrl}api/information/upcoming`);

                setBerita(response.data.data)
                setAgenda(response2.data.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const options = {
        loop: true,
        margin: 30,
        dots: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    const options_course = {
        loop: false,
        margin: 30,
        dots: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            },
            1400: {
                items: 4
            }
        }
    };

    const items = [

        {
            icon: <img src={serviceImage2} alt="" />,
            title: 'Kurikulum Pendidikan',
            content: 'Perpaduan antara Kurikulum Nasional (KEMENDIKBUD) dan Kurikulum Pesantren (KEMENAG) KBM Full Day'
        },
        {
            icon: <img src={serviceImage3} alt="" />,
            title: 'Program Unggulan',
            content: 'Tahfidz Al-Quran Target Lulus SMP Hafal Al-Quran 30 Juz'
        },
        {
            icon: <img src={serviceImage1} alt="" />,
            title: 'Pengawasan Santri',
            content: 'Sistem Pengawasan Anak 24 Jam dengan didampingi Guru Pendamping '
        },
    ];





    const moveDetail = (beritaDetail) => {
        navigate("/detail-berita", {
            state: {
                data: beritaDetail
            },
        });
    };

    // Fungsi untuk memotong teks dengan maksimal panjang dan menambahkan tiga titik jika lebih panjang
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    return (
        <>

            <>

                <FloatButton.Group
                    shape="square"
                    trigger="hover"
                    type="primary"
                    style={{
                        right: 35,
                    }}
                    icon={<FaBookOpenReader />}
                >
                    <FloatButton icon={<IoQrCodeOutline />} tooltip={<div id="myqrcode">
                        <QRCode
                            value="https://www.ppmtqni-brebes.ponpes.id/"
                            bgColor="#fff"
                            icon={logoQR}
                            iconSize={60}
                            style={{
                                margin: 16,
                            }}
                        />
                    </div>
                    } href='/e-library' />
                    <FloatButton icon={<IoLibraryOutline />} tooltip={<div>E-Library</div>} href='/e-library' />
                    <FloatButton icon={<FaCalendarWeek />} tooltip={<div>Kalender Akademik</div>} href='/pendidikan-formal' />
                    <FloatButton icon={<MdOutlineAppRegistration />} tooltip={<div>Info Pendaftaran</div>} href='/info-daftar' />

                </FloatButton.Group>
            </>
            <Header />
          
            <BannerVideo />
            <section className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel className="owl-theme" {...options}>
                                {items.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="icon">{item.icon}</div>
                                        <div className="down-content">
                                            <h4>{item.title}</h4>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <AgendaBeranda agenda={agenda}/>
            <section className="apply-now" id="apply">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 align-self-center">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item">
                                        <h3>PROGRAM UNGGULAN</h3>
                                        <p>
                                            <b> Tahfidz Al-Qur'an Target Lulus SMP Hafal Al-Qur'an 30 Juz </b>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="item">
                                        <h3>KURIKULUM PENDIDIKAN</h3>
                                        <p>
                                            <ul>
                                                <li>• Perpaduan antara Kurikulum Nasional (KEMENDIKBUD) dan Kurikulum Pesantren (KEMENAG)</li>
                                                <li>• KBM Full Day <i> <b> (Boarding School)</b></i></li>
                                                <li>• Sistem Pendidikan Pesantren Sorogan</li>
                                                <li>• Sistem Pengawasan Anak 24 Jam dengan didampingi Guru Pendamping</li>
                                            </ul>
                                        </p>
                                        <div className="main-button-yellow">
                                            <div className="scroll-to-section">
                                                <a href="#contact">Selengkapnya</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item">
                                        <h3>FASILITAS</h3>
                                        <p>
                                            <ul>
                                                <li>• Luas lahan 8 hektar</li>
                                                <li>• Sarana Ibadah Masjid 2 Lantai kapasitas 3500 Jamaah</li>
                                                <li>• Gedung Asrama Putra 3 Lantai Kapasitas 1.500 Santri</li>
                                                <li>• Gedung Asrama Putri 3 Lantai Kapasitas 1.500 Santri</li>
                                                <li>• Setiap Ruang Belajar & Ruang Tidur dijaga pengawas 24 Jam </li>
                                                <li>• Setiap Ruang Belajar dilengkapi CCTV</li>
                                                <li>• Setiap Kamar Tidur dilengkapi 6-7 kamar mandi dan toilet</li>
                                                <li>• Sarana Olahraga</li>
                                                <li>• Mini Market</li>
                                                <li>• Area Parkir Luas</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-courses" id="courses">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>Berita Terkini</h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                          <div className='berita-beranda'>
                          {berita.map((course, index) => (
                                    <div className="col-lg-12" key={index}>
                                        <div className="meeting-item" onClick={() => moveDetail(course)} style={{ cursor: "pointer" }}>
                                            <div className="thumb">
                                                <img
                                                    src={course?.image_url || 'default_image_url.jpg'}
                                                    alt={course?.title}
                                                    style={{ width: '100%', height: "200px", cursor: "pointer" }}
                                                />
                                            </div>
                                            <div className="down-content">
                                                <div className="date">
                                                    <h6>
                                                        {new Date(course?.date).toLocaleDateString('en-GB', {
                                                             day: '2-digit',
                                                            month: 'short',
                                                        })}
                                                    </h6>
                                                </div>
                                                <h6 title={course?.title}>{truncateText(course.title, 38)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                          </div>
                               
                         
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-facts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Pengajar dan Santri</h2>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="count-area-content">
                                                <div className="count-digit">52</div>
                                                <div className="count-title">Pengajar</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="count-area-content">
                                                <div className="count-digit">34</div>
                                                <div className="count-title">Alumni Hafidz</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="count-area-content new-students">
                                                <div className="count-digit">404</div>
                                                <div className="count-title">Santri Putra</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="count-area-content">
                                                <div className="count-digit">454</div>
                                                <div className="count-title">Santri Putri</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
            <section className="contact-us" id="contact">


                <section className="bg-light mt-lg-5 p-5">
                    <div className="container-fluid p-lg-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-lg-6">
                                <div className="col-lg-8 mx-auto">

                                    <div className="section-heading">
                                        <h2>Hubungi Kami</h2>
                                    </div>

                                    <List
                                        itemLayout="horizontal"
                                        dataSource={datacontact}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<div style={{ fontSize: "18px" }}>{item.icon}</div>}
                                                    title={<a >{item.title}</a>}
                                                    description={<a href={item.desc}>{item.desc}</a>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mt-5 mt-lg-0">
                                    <MapComp />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </section>
        </>

    )
}

export default Beranda