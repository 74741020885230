import React from 'react';
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const meetingsData = [
  {
    date: 'Nov 10',
    title: 'New Lecturers Meeting',
    imageSrc: 'https://source.unsplash.com/800x600/?meeting',
    description: 'Morbi in libero blandit lectus cursus ullamcorper.'
  },
  {
    date: 'Nov 24',
    title: 'Online Teaching Techniques',
    imageSrc: 'https://source.unsplash.com/800x600/?teaching',
    description: 'Morbi in libero blandit lectus cursus ullamcorper.'
  },
  {
    date: 'Nov 26',
    title: 'Higher Education Conference',
    imageSrc: 'https://source.unsplash.com/800x600/?conference',
    description: 'Morbi in libero blandit lectus cursus ullamcorper.'
  },
  {
    date: 'Nov 30',
    title: 'Student Training Meetup',
    imageSrc: 'https://source.unsplash.com/800x600/?training',
    description: 'Morbi in libero blandit lectus cursus ullamcorper.'
  },
];

const BeritaTerkini = () => {
  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Berita Terkini</h2>
          <h6>List berita terbaru </h6>
        </div>
      </div>
    </div>
  </section>
        <section className="meetings-page" id="meetings">
          <div className="container">
            <div className="row">
            <div className="col-lg-2">
                            <div className="categories bg-light p-3" style={{borderRadius:"8px"}}>
                                <h6 className='pb-3 mb-3' style={{borderBottom:"1px solid #EEEEEE"}}>Arsip Berita</h6>
                                <ul>
                                    <li className='pb-1 ' style={{color:"#8A9A60", textDecoration:"underline"}}>
                                        <a href="#">2024</a>
                                    </li>
                                    <li className='pb-1 '>
                                        <a href="#">2025</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
              <div className="col-lg-10">
                <section>
                  <div className="container">
                    <div className="row gx-3 align-items-center justify-content-between">
                      <div className="col-md-12 order-2 order-md-1">
                        <div className="row">
                          {meetingsData.map((meeting, index) => (
                            <div className="col-lg-4" key={index}>
                                <Link to="/detail-berita" >
                              <div className="meeting-item">
                                <div className="thumb">
                                    <img src={meeting.imageSrc} alt={meeting.title}  style={{width:"100%"}} />
                                </div>
                                <div className="down-content">
                                  <div className="date">
                                    <h6>
                                      {meeting.date}
                                    </h6>
                                  </div>
                                  <Link>
                                    <h4>{meeting.title}</h4>
                                  </Link>
                                </div>
                              </div>
                                </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    </div>
  );
};

export default BeritaTerkini;
