import React, { useState } from "react";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Pendaftaran.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import { FaUpload } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';
import Accordion from "./Accordion";
import { Carousel, Card, Col, Row, Badge, Result, Button,Tabs, Typography } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { Space } from 'antd';

const contentStyle = {
  height: '100%',
  color: '#fff',
  textAlign: 'center',
  // background: '#F0F0FD',
  background: '#364d79',

  padding: "20px",
};






const contentStyle2 = {
  height: '100%',
  color: '#ffffff',
  // textAlign: 'center',
  background: '#364d79',
  padding: "20px",
};



const questionsAnswersSMP = [
  {
    question: "Informasi",
    answer:
      "Pendaftaran peserta didik baru dapat dilakukan dengan mengunjungi kantor pusat Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris, yang berlokasi di Jl. Jatibarang - Sitanggal Km 7, Dukuh Kedawon - Larangan - Brebes. Selain itu, atau dapat mendaftar secara online melalui menu 'Daftar Online' ",
  },
  {
    question: "Persyaratan Umum",
    answer: ["Lulus SD / MI", "Berkeinginan Kuat untuk menghafal Al-Qur'an", "Sehat Jasmani Rohani", "Lancar Membaca Alqur'an (Fasyeh)"],
  },
  {
    question: "Persyaratan Mendaftar",
    answer: ["Mengisi Formulir Pendaftaran", "Membayar Uang Pendaftaran Rp. 150.000", "Menyertakan Foto Copy Akte Kelahiran (2 Lembar)", "Menyertakan Foto Copy Kartu Keluarga ( 2 Lembar)", "Menyertakan Foto Copy KTP Bapak & Ibu ( 2 Lembar)", "Menyertakan Foto Copy Raport SD/MI dari kelas 4, 5, dan 6", "Menyertakan Pas Foto Berwarna 3x4"],
  },
  {
    question: "Biaya",
    answer: `Biaya yang dikenakan sebesar Rp. 800.000`,
  },
  {
    question: "Proses Pendaftaran",
    answer: `Proses pendaftaran berlangsung dengan cara mengisi formulir yang tersedia dan membawa dokumen persyaratan yang diperlukan jika mendaftar langsung di kantor pusat. Sedangkan untuk pendaftaran secara online, prosesnya melibatkan pengisian formulir pendaftaran online dan mengunggah file dokumen yang dibutuhkan.`,
  },
];

const questionsAnswersSMA = [
  {
    question: "Informasi",
    answer:
      "Pendaftaran peserta didik baru dapat dilakukan dengan mengunjungi kantor pusat Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris, yang berlokasi di Jl. Jatibarang - Sitanggal Km 7, Dukuh Kedawon - Larangan - Brebes. Selain itu, atau dapat mendaftar secara online melalui menu 'Daftar Online' ",
  },
  {
    question: "Persyaratan Umum",
    answer: ["Lulus SMP / MTS", "Berkeinginan Kuat untuk menghafal Al-Qur'an", "Sehat Jasmani Rohani", "Lancar Membaca Alqur'an (Fasyeh)"],
  },
  {
    question: "Persyaratan Mendaftar",
    answer: ["Mengisi Formulir Pendaftaran", "Membayar Uang Pendaftaran Rp. 150.000", "Menyertakan Foto Copy Akte Kelahiran (2 Lembar)", "Menyertakan Foto Copy Kartu Keluarga ( 2 Lembar)", "Menyertakan Foto Copy KTP Bapak & Ibu ( 2 Lembar)", "Menyertakan Foto Copy Raport SMP/MTS dari kelas 8 dan 9", "Menyertakan Pas Foto Berwarna 3x4"],
  },
  {
    question: "Biaya",
    answer: `Biaya yang dikenakan sebesar Rp. 800.000`,
  },
  {
    question: "Proses Pendaftaran",
    answer: `Proses pendaftaran berlangsung dengan cara mengisi formulir yang tersedia dan membawa dokumen persyaratan yang diperlukan jika mendaftar langsung di kantor pusat. Sedangkan untuk pendaftaran secara online, prosesnya melibatkan pengisian formulir pendaftaran online dan mengunggah file dokumen yang dibutuhkan.`,
  },
];

const { Text } = Typography;
const { TabPane } = Tabs;

const InfoDaftar = () => {


  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Informasi Pendaftaran</h2>
                {/* <h6>Informasi Pendaftaran SMP dan SMA Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris</h6> */}
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">

          <div className="container">
            
            <div className="mb-5 text-center">
              <div className="" style={{fontSize:"20px"}}>
          MEKANISME PENERIMAAN SANTRI BARU 
              </div>

              <div className="ant-result-subtitle">
                <Text type="secondary" style={{fontSize:"16px"}}>
           (  Tahun Ajaran 2024 - 2025 )
                </Text>
              </div>
            </div>

            <div className="mb-5">
              <Carousel >
                <div>
                  <div style={contentStyle2}>
                  <Space className="d-flex gap-3">
                      <Badge dot>
                        <NotificationOutlined
                          style={{
                            fontSize: 20,
                            color: '#FFFFFF',
                          }}
                        />
                      </Badge>
                      <Badge style={{
                        color: '#FFFFFF',
                      }}>
                        <h6 style={{ color: "#ffffff", textTransform:"uppercase" }}> GELOMBANG 1 </h6>
                       <p style={{color:"white"}}>01 Januari - 04 Maret 2024</p>
                      </Badge>
                    </Space>
                  </div>

                  <Row gutter={10}>
                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="WAKTU TES" color="orange" >
                        <Card title="SELASA" size="medium" className="mb-3">
                          5 Maret 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>

                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="PENGUMUMAN" color="darkgreen" >
                        <Card title="KAMIS" size="medium" className="mb-3">
                          14 Maret 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>

                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="PEMBELIAN SERAGAM" color="olive" >
                        <Card title="RABU" size="medium" className="mb-3">
                          20 Maret 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>


                  </Row>
                </div>
                <div>
                  <div style={contentStyle2}>
                    <Space className="d-flex gap-3">
                      <Badge dot>
                        <NotificationOutlined
                          style={{
                            fontSize: 20,
                            color: '#FFFFFF',
                          }}
                        />
                      </Badge>
                      <Badge style={{
                        color: '#FFFFFF',
                      }}>
                        <h6 style={{ color: "#ffffff", textTransform:"uppercase" }}> GELOMBANG 2 </h6>
                       <p style={{color:"white"}}>07 Maret - 09 Juni 2024</p>
                      </Badge>
                    </Space>
                  </div>

                  <Row gutter={10}>


                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="WAKTU TES" color="purple" >
                        <Card title="SENIN" size="medium" className="mb-3">
                          5 Juni 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>

                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="PENGUMUMAN" color="gray" >
                        <Card title="RABU" size="medium" className="mb-3">
                          19 Juni 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>

                    <Col span={8} style={contentStyle}>

                      <Badge.Ribbon text="PEMBELIAN SERAGAM" color="magenta" >
                        <Card title="SELASA" size="medium" className="mb-3">
                          25 Juni 2024
                        </Card>
                      </Badge.Ribbon>
                    </Col>


                  </Row>
                </div>
              </Carousel>
            </div>

            <div className="mb-5 text-center mt-5">
              <div className="" style={{fontSize:"20px"}}>
          INFORMASI PENDAFTARAN
              </div>

              <div className="ant-result-subtitle">
                <Text type="secondary" style={{fontSize:"16px"}}>
                ( Informasi Mengenai Proses Pendaftaran, Persyaratan, Biaya, dan Informasi Tambahan yang Berkaitan dengan SMP dan SMA Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris )
                </Text>
              </div>
            </div>

            <div className="info-tabs">
      <Tabs activeKey={activeTab} onChange={handleTabChange} centered>
        <TabPane tab="SMP" key="1">
        <div className="component">
              <div className="illustration">
              </div>
              <Accordion questionsAnswers={questionsAnswersSMP} title="SMP" />
            </div>
        </TabPane>
        <TabPane tab="SMA" key="2">
          <div className="component">
              <div className="illustration">
              </div>
              <Accordion questionsAnswers={questionsAnswersSMA} title="SMA" />
            </div>
        </TabPane>
      </Tabs>
    </div>
          




           
          </div>

          <Footer />
        </section>
      </>
    </div>
  );
};

export default InfoDaftar;
