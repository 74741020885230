import React from 'react'
import './Ekstrakulikuler.css';
import { SlBookOpen } from "react-icons/sl";
import {PiDiamondFill  } from "react-icons/pi";

import { Collapse } from 'antd';
import { Space, Table, Tag } from 'antd';

const columns = [
  {
    title: 'WAKTU',
    key: 'waktu',
    dataIndex: 'waktu',
    render: (_, record) => (
      <>
       
            <Tag color="darkgreen">
         {record.waktu}
            </Tag>
         
      </>
    ),
  },
  {
    title: 'Kegiatan',
    key: 'kegiatan',
    render: (_, record) => (
      <Space size="middle">
        <ul style={{ display: 'flex', flexDirection: 'row', listStyleType: 'none', padding: 0 }}>
          {record.kegiatan.map((item, index) => (
            <li key={index} style={{ marginRight: '10px' }}>{item}</li>
          ))}
        </ul>
      </Space>
    ),
  },
];
const data = [
  {
    key: '1',
    waktu: '03.30 - 04.30',
    kegiatan: ['Shalat Tahajud', 'Shalat Hajat', "Menghafal Al-Qur'an"],
  },

  {
    key: '2',
    waktu: '04.30 - 05.00',
    kegiatan: ['Shalat Subuh Berjamaah'],
  },


  {
    key: '3',
    waktu: '05.00 - 07.00',
    kegiatan: ["Setoran Hafalan Al-Qur'an", "Shalat Dhuha"],
  },


  {
    key: '4',
    waktu: '07.00 - 07.30',
    kegiatan: ['Makan Pagi', 'Persiapan Berangkat Sekolah'],
  },


  {
    key: '5',
    waktu: '07.30 - 12.00',
    kegiatan: [ "Kegiatan Belajar Mengajar"],
  },

  {
    key: '6',
    waktu: '12.00 - 13.00',
    kegiatan: [ "Makan Siang dan Shalat Dzuhur Berjamaah"],
  },

  {
    key: '7',
    waktu: '13.00 - 15.00',
    kegiatan: [ "Kegiatan Belajar Mengajar Pondok Pesantren Siang"],
  },

  {
    key: '8',
    waktu: '15.00 - 16.00',
    kegiatan: [ "Shalat Ashar Berjamaah"],
  },

  {
    key: '9',
    waktu: '16.00 - 17.00',
    kegiatan: [ "Kegiatan Belajar Mengajar Pondok Pesantren Sore"],
  },

  {
    key: '10',
    waktu: '17.00 - 18.15',
    kegiatan: [ "Makan Sore dan Sholat Maghrib Berjamaah"],
  },

  {
    key: '11',
    waktu: '18.15 - 19.30',
    kegiatan: [ "Setoran Hafalan dan Menghafal Al-Quran"],
  },

  {
    key: '12',
    waktu: '19.30 - 20.00',
    kegiatan: [ "Shalat Isya Berjamaah"],
  },

  {
    key: '13',
    waktu: '20.00 - 20.30',
    kegiatan: [ "Makan Jaburan / Snack"],
  },

  {
    key: '14',
    waktu: '20.30 - 03.30',
    kegiatan: [ "Istirahat Malam {Wajib Tidur)"],
  },



  
  
];


const visismp = (
 <div>
  <div className="container ">
    {/*first section*/}
    <div className="row align-items-center how-it-works d-flex">
      <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
      <div className="col-6">
        <h5>Visi </h5>
        <p>
        Terwujudnya Sekolah Menengah Pertama Rujukan dalam Membina Generasi  Qur’ani yang Beriman, Bernalar Kritis, Berkebhinekaan, Unggul dalam Prestasi.
        </p>
      </div>
    </div>
    {/*path between 1-2*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner top-right" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner left-bottom" />
      </div>
    </div>
    {/*second section*/}
    <div className="row align-items-center justify-content-end how-it-works d-flex">
      <div className="col-9 text-right">
      <h5>Misi</h5>
       <ul className='mt-2'>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membentuk manusia yang menjadikan Al-Qur’an sebagai pedoman hidupnya, membaca, menghafal dan memahaminya isinya, serta mengamalkannya dalam seluruh aspek kehidupan.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan lulusan yang bertakwa kepada Allah SWT, berakhlak mulia.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan lulusan yang mampu melihat sesuatu dari berbagai sudut pandang dan berpikiran terbuka dengan cara memproses informasi dan gagasan, kemudian menganalis dan melakukan penalaran serta mengambil keputusan.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mengembangkan potensi peserta didik menjadi lulusan yang berprestasi dalam bidang akademik dan non akademik dengan mengikutsertakan peserta didik untuk kegiatan lomba minimal tingkat kecamatan hingga sampai tingkat internasional.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang terbuka terhadap keberagaman budaya Indonesia dengan cara menghormati dan menghargai perbedaan.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan proses pembelajaran dengan berbagai vairasi pendekatan dan metode, yang aktif, inovatif, kreatif, efektif, menyenangkan dan Islami.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan partisipasi semua warga sekolah dalam menumbuhkan lingkungan pendidikan yang aman, nyaman dan Islami</li>
       </ul>
      </div>
      <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
      <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
    </div>
    {/*path between 2-3*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner right-bottom" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner top-left" />
      </div>
    </div>
  </div>
 </div>
);

const visisma = (
  <div>
   <div className="container mt-5">
    <h2 className="pb-3 pt-2 border-bottom mb-5">
      SMA
    </h2>
    {/*first section*/}
    <div className="row align-items-center how-it-works d-flex">
      <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
      <div className="col-6">
        <h5>Visi</h5>
        <p>
        Terbentuknya generasi Qur’ani, unggul dalam prestasi, berdaya saing global.
        </p>
      </div>
    </div>
    {/*path between 1-2*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner top-right" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner left-bottom" />
      </div>
    </div>
    {/*second section*/}
    <div className="row align-items-center justify-content-end how-it-works d-flex">
      <div className="col-9 text-right">
      <h5>Misi</h5>
       <ul className='mt-2'>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membentuk manusia yang mencerminkan nilai Islam Ahlus Sunah Waljamaah yang moderat.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang hafal Al-Qur’an dan Hadits.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang berkepribadian Indonesia.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan proses pembelajaran kontekstual berbasis teknologi.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membekali peserta didik dengan Bahasa Inggris, Bahasa Arab, serta mampu berdaya saing global.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan partisipasi semua warga sekolah dalam menumbuhkan lingkungan pendidikan yang aman, nyaman dan Islami.</li>
       </ul>
      </div>
      <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
      <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
    </div>
    {/*path between 2-3*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner right-bottom" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner top-left" />
      </div>
    </div>



    
   
  </div>
  </div>
 );
 

const kurikulum = (
  <div>
   <div className="container ">
     {/*first section*/}
     <div className="row align-items-center how-it-works d-flex">
       <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
         <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
       </div>
       <div className="col-6">
         <h5>Kurikulum</h5>
         <p>
         Penerapan Kurikulum Merdeka berdasarkan Keputusan Kepala Badan Standar, Kurikulum dan Asesmen Pendidikan Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi (Kemendikbudristek) No. 022/H/KR/2023 tentang Satuan Pendidikan Pelaksana Implementasi Kurikulum Merdeka Pada Tahun Ajaran 2023/2024.
         </p>
       </div>
     </div>
    </div>
  </div>
 );

 const jadwal = (
  <div>
   <div className="container ">
     <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  </div>
 );
const items = [
  {
    key: '1',
    label: 'Visi & Misi',
    children: visismp,
  },
  {
    key: '2',
    label: 'Kurikulum',
    children: kurikulum,
  },
  {
    key: '3',
    label: 'Jadwal Kegiatan',
    children: jadwal,
  },
];

const itemsma = [
  {
    key: '1',
    label: 'Visi & Misi',
    children: visisma,
  },
  {
    key: '2',
    label: 'Kurikulum',
    children: kurikulum,
  },
  {
    key: '3',
    label: 'Jadwal Kegiatan',
    children: jadwal,
  },
];

const TableSMPSMA = () => {
  return (
    <section className="pricing-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="price-card featured">
            <h2>SMP</h2>
           
          <Collapse className='mt-3 mb-4' items={items} bordered={false} defaultActiveKey={['1']} />


            <h2>SMA</h2>
          
          <Collapse className='my-3' items={itemsma} bordered={false} defaultActiveKey={['1']} />
          </div>


          
        </div>

     
      </div>
    </div>
  </section>
  
  )
}

export default TableSMPSMA