import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Typography, List } from 'antd';
import { MapComp } from '../../components/MapComp'
import { IoGlobeOutline, IoHomeOutline, IoMailOutline } from 'react-icons/io5'

const { Text } = Typography;

const datacontact = [
    {
      title: 'Alamat',
      icon: <IoHomeOutline/>,
      desc: ' Jl. Jatibarang - Sitanggal Km 7, Dukuh Kedawon - Larangan - Brebes'
    },
   
    {
      title: 'Email',
      icon: <IoMailOutline/>,
      desc:' ppmtqni@gmail.com'
    },
    {
      title: 'Website',
      icon:<IoGlobeOutline/>,
      desc:'www.ppmtqni-brebes.ponpes.id'
    },
  ];

const HubungiKami = () => {
    return (
        <div>
            <>
                {/* ***** Header Area Start ***** */}
                <Header />
                {/* ***** Header Area End ***** */}
                <section className="heading-page header-text" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Hubungi Kami </h2>
                                {/* <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="meetings-page" id="meetings">
                    <div className="container">
                    <div className="text-center">
              <div className="" style={{fontSize:"20px"}}>
         INFORMASI BANTUAN DAN KONTAK
              </div>

              <div className="ant-result-subtitle">
                <Text type="secondary" style={{fontSize:"16px"}}>
                Silakan hubungi kami melalui informasi kontak yang tertera di bawah ini atau kunjungi kantor pusat kami untuk mendapatkan layanan dan informasi yang anda butuhkan.
                </Text>
              </div>
            </div>
          
                                <section className='contact-us'>
                                <section className="bg-light mt-lg-5 p-5">
  <div className="container-fluid p-lg-0">
    <div className="row g-0 align-items-center">
      <div className="col-lg-6">
        <div className="col-lg-8 mx-auto">
         
          <div className="section-heading">
                                <h2>Hubungi Kami</h2>
                            </div>
        
          <List
    itemLayout="horizontal"
    dataSource={datacontact}
    renderItem={(item, index) => (
      <List.Item>
        <List.Item.Meta
          avatar={ <div style={{fontSize:"18px"}}>{item.icon}</div>  } 
          title={<a >{item.title}</a>}
          description={<a href={item.desc}>{item.desc}</a>}
        />
      </List.Item>
    )}
  />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mt-5 mt-lg-0">
        <MapComp />
        </div>
      </div>
    </div>
  </div>
</section>
                                </section>

                            </div>
                    <Footer />
                </section>
            </>

        </div>
    )
}

export default HubungiKami