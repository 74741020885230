import React, { useEffect, useState } from 'react'
import "./Header.css"
import "../css/global.css"
import logoPonpes from "../images/ni.png"
import { Link, useLocation } from 'react-router-dom';
import { FaAngleDown, FaChevronRight, FaChevronDown, FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";



const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            // Replace jQuery code with native JavaScript
            const scroll = window.scrollY;
            const box = document.querySelector('.header-text').offsetHeight;
            const header = document.querySelector('header').offsetHeight;

            if (scroll >= box - header) {
                document.querySelector("header").classList.add("background-header");
            } else {
                document.querySelector("header").classList.remove("background-header");
            }
        };

        const handleFilterClick = (e) => {
            const filters = document.querySelectorAll('.filters ul li');
            filters.forEach(filter => filter.classList.remove('active'));
            e.target.classList.add('active');

            const data = e.target.getAttribute('data-filter');
            // Replace $grid.isotope() with your logic
        };

        // Replace Owl Carousel initialization with your logic
        const initOwlCarousel = (selector, options) => {
            // Implement your Owl Carousel logic here
        };

        const handleMenuTriggerClick = () => {
            setMenuOpen(!isMenuOpen);
        };

        window.addEventListener('scroll', handleScroll);

        const filterItems = document.querySelectorAll('.filters ul li');
        filterItems.forEach(item => item.addEventListener('click', handleFilterClick));

        initOwlCarousel('.owl-service-item', { items: 3, loop: true, /* other options */ });
        initOwlCarousel('.owl-courses-item', { items: 4, loop: true, /* other options */ });

        const menuTrigger = document.querySelector('.menu-trigger');
        if (menuTrigger) {
            menuTrigger.addEventListener('click', handleMenuTriggerClick);
        }



        // Cleanup function for removing event listeners if needed
        return () => {
            window.removeEventListener('scroll', handleScroll);
            filterItems.forEach(item => item.removeEventListener('click', handleFilterClick));
            if (menuTrigger) {
                menuTrigger.removeEventListener('click', handleMenuTriggerClick);
            }
        };
    }, []);

    return (
        <div>
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav pb-3">
                                {/* ***** Logo Start ***** */}
                                <img
                                    src={logoPonpes}
                                    alt=""
                                    style={{ width: 50, marginTop: 15 }}
                                    className={location.pathname === '/beranda' || location.pathname === '/'   ? 'd-none' : ''}
                                />


                                {/* ***** Logo End ***** */}
                                {/* ***** Menu Start ***** */}
                                <ul className={isMenuOpen ? "toggle-active" : "nav"}>
                                    <li className="scroll-to-section">
                                        <Link to="/beranda" className={location.pathname === '/beranda' ? 'active' : ''}>
                                            Beranda
                                        </Link>
                                    </li>
                                    <li className="has-sub">
                                        <Link className={location.pathname === '/sambutan-pengasuh' || location.pathname === '/visi-misi' || location.pathname === '/tenaga-pendidik'  || location.pathname === '/sejarah-pondok'  || location.pathname === '/struktur-organisasi' ? 'active' : ''}>Profil <FaAngleDown /></Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/sambutan-pengasuh" >Sambutan Pengasuh</Link>
                                            </li>
                                            <li>
                                                <Link to="/visi-misi"  >Visi & Misi</Link>
                                            </li>

                                            <li>
                                                <Link to="/sejarah-pondok" >Sejarah Pondok</Link>
                                            </li>
                                            <li>
                                                <Link to="/struktur-organisasi" >Struktur Organisasi</Link>
                                            </li>
                                            <li>
                                                <Link to="/tenaga-pendidik" >Tenaga Pendidik</Link>
                                            </li>

                                        </ul>
                                    </li>


                                    <li className="has-sub">

                                        <Link className={location.pathname === '/pendidikan-formal' || location.pathname === '/kurikulum-pesantren' || location.pathname === '/ekstrakulikuler' || location.pathname === '/prestasi' ? 'active' : ''} >Akademik & Sekolah<FaAngleDown /></Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/pendidikan-formal">Pendidikan Formal</Link>
                                            </li>
                                            <li>
                                                <Link to="/kurikulum-pesantren">Kurikulum Pesantren</Link>
                                            </li>

                                            <li>
                                                <Link to="/ekstrakulikuler">Ekstrakurikuler</Link>
                                            </li>

                                            <li>
                                                <Link to="/prestasi">Prestasi</Link>
                                            </li>
                                        </ul>
                                    </li>


                                    <li className="has-sub">
                                        <Link className={location.pathname === '/info-daftar' || location.pathname === '/daftar-online' ? 'active' : ''}>Pendaftaran <FaAngleDown /></Link>

                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/info-daftar">Info Pendaftaran</Link>
                                            </li>

                                            <li>
                                                <Link to="/daftar-online">Daftar Online</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="has-sub">
                                        <Link className={location.pathname === '/agenda-mendatang' || location.pathname === '/berita-terkini' || location.pathname === '/detail-berita' ? 'active' : ''}>Informasi<FaAngleDown /></Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/berita-terkini">Berita Terkini</Link>
                                            </li>
                                            <li>
                                                <Link to="/agenda-mendatang">Agenda Mendatang</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="scroll-to-section">
                                        <Link to="/galeri" className={location.pathname === '/galeri' ? 'active' : ''}>Galeri</Link>
                                    </li>

                                    <li className="scroll-to-section">
                                        <Link to="/e-library" className={location.pathname === '/e-library' ? 'active' : ''}>E-Library</Link>
                                    </li>

                                    <li className="scroll-to-section">
                                        <Link to="/hubungi-kami" className={location.pathname === '/hubungi-kami' ? 'active' : ''}>Hubungi Kami</Link>
                                    </li>


                                </ul>
                                <a className="menu-trigger">
                                    <span>Menu</span>
                                </a>
                                {/* ***** Menu End ***** */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header