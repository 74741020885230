import React from 'react'
import videoBG from "../images/course-video.mp4"
import "./Banner.css"
import logoPonpes from "../images/ni.png"


const BannerVideo = () => {
  return (
    <div>
         <section className="section main-banner" id="top" data-section="section1">
                <video autoPlay="true" muted="true" loop="true" id="bg-video">
                    <source src={videoBG} type="video/mp4" />
                </video>
                <div className="video-overlay header-text text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="caption ">
                                    {/* <h6>ٱلسَّلَامُ عَلَيْكُمْ وَرَحْمَةُ ٱللَّٰهِ وَبَرَكَاتُهُ</h6> */}
                                    <img src={logoPonpes} width="150px" />
                                    <h2 >PPMTQNI </h2>
                                    <p>
                                        <b>
                                            <i>
                                              Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris
                                            </i>
                                        </b>
                                   
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default BannerVideo