    import React from 'react'
    import { Link } from 'react-router-dom'
    import { format } from 'date-fns';


    const AgendaBeranda = (props) => {
        const berita = props.agenda;

        // Urutkan berita berdasarkan tanggal secara descending
        const sortedBerita = berita
            .map(item => ({ ...item, date: new Date(item.date) })) // Pastikan tanggalnya adalah objek Date
            .sort((a, b) => b.date - a.date);

        // Ambil hanya 5 berita terbaru
        const latestBerita = sortedBerita.slice(0, 5);


        return (
            <section className="upcoming-meetings bg-islamic" id="meetings">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>Agenda Mendatang</h2>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <section className="timeline_area section_padding_130">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-sm-8 col-lg-6">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="apland-timeline-area">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="apland-timeline-area">
                                                            {latestBerita.map((item) => {
                                                                const date = item.date;
                                                                const formattedDate = format(date, 'yyyy').toUpperCase();

                                                                return (
                                                                    <div key={item.id} className="single-timeline-area">
                                                                        <div
                                                                            className="timeline-date wow fadeInLeft"
                                                                            data-wow-delay="0.1s"
                                                                            style={{
                                                                                visibility: "visible",
                                                                                animationDelay: "0.1s",
                                                                                animationName: "fadeInLeft"
                                                                            }}
                                                                        >
                                                                            <p>{formattedDate}</p>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <div
                                                                                    className="single-timeline-content d-flex wow fadeInLeft"
                                                                                    data-wow-delay="0.3s"
                                                                                    style={{
                                                                                        visibility: "visible",
                                                                                        animationDelay: "0.3s",
                                                                                        animationName: "fadeInLeft"
                                                                                    }}
                                                                                >
                                                                                    <div className="date col-3">
                                                                                        <p style={{ color: "#859A5B", fontWeight: "600" }}>
                                                                                            {format(date, 'MMMM').toUpperCase()}
                                                                                        </p>
                                                                                        <p style={{ color: "#2e2e2e", fontSize: "23px", fontWeight: "800" }}>
                                                                                            {format(date, 'dd')}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="timeline-text">
                                                                                        <label>{item.title}</label>
                                                                                        <p>
                                                                                            {item.description.length > 50
                                                                                                ? `${item.description.substring(0, 50)}...`
                                                                                                : item.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-button-red mt-5">
                                        <Link to="/agenda-mendatang">Selengkapnya</Link>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    export default AgendaBeranda
