import React from "react";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Prestasi.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Typography } from 'antd';
import CalendarAkademik from "./CalendarAkademik";
import TableSMPSMA from "./TableSMPSMA";


const { Text } = Typography;


const PendidikanFormal = () => {



    return (
        <div>
            <>
                {/* ***** Header Area Start ***** */}
                <Header />
                {/* ***** Header Area End ***** */}
                <section className="heading-page header-text" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Pendidikan Formal</h2>
                                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="" id="meetings">

                    <section>
                        <div className="container">
                            <header className="py-5">
                                <div className="container px-5">
                                    <div className="row justify-content-start">
                                        <div className="col-12">

                                            <div className="mb-3 mt-5">
                                                <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                                                    Menyongsong Masa Depan Melalui Ilmu Pengetahuan
                                                </div>

                                                <div className="ant-result-subtitle">
                                                    <Text type="secondary" style={{ fontSize: "16px" }}>
                                                        Landasan yang kokoh bagi masa depan dengan menggali potensi akademik di dalam pendidikan formal. Di sini, kami mendedikasikan diri untuk memberikan fondasi yang kuat bagi siswa-siswa kami, mempersiapkan mereka untuk tantangan global yang kompleks. Dari kurikulum yang terstruktur hingga pengembangan keterampilan yang holistik, kami memandu siswa dalam mengeksplorasi dunia ilmu pengetahuan secara menyeluruh.
                                                    </Text>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </header>

                            <div>

                                <TableSMPSMA />
                            </div>

                            {/* <div>
                                <div className="mt-5 mb-5">
                                    <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                                        KALENDER AKADEMIK
                                    </div>
                                </div>
                                <CalendarAkademik />
                            </div> */}

                        </div>
                    </section>

                    <Footer />
                </section>
            </>
        </div>
    );
};

export default PendidikanFormal;
