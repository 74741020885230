import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'
import Footer from '../../components/Footer'
import imgPengasuh from "../../images/pengasuh.png"
import ImgBg from "../../images/light-pattern.jpg"

const SambutanPengasuh = () => {
  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Sambutan</h2>
                <h6>Dari Pengasuh Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <section>
                  <div className="container">
                    <div className="row gx-5 align-items-center justify-content-end bg-light ps-3 py-4 pe-0" style={{borderRadius:"35px", backgroundColor:"#F2F2F2"}} >
                      <div className="col-md-6 pe-5 order-2 order-md-1 d-flex">
                        <div className='quote-left me-3'>
                          <FaQuoteLeft style={{fontSize:"35px"}} />
                        </div>
                        <div className="mt-5 mt-md-0 pb-3">
                          <p className="lead pb-3">
                            Dengan Memuji Rahmat dan Mengharap Ridho Allah Subhanahu Wata'ala
                          </p>

                          <p className="lead pb-3">
                            Kami bertekad untuk berkarya dengan sepenuh hati
                          </p>
                          <p className="lead  pb-5">
                            Melayani umat islam dengan menyelenggarakan Pendidikan Pondok Pesantren Berbasis Modern dengan Aqidah Ahlussunnah Wal Jama'ah, untuk membangun Generasi Profesional yang Berakhlakul Karimah Berbasis Al Aqur'an.
                            <span className='ms-3' >
                              <FaQuoteRight />
                            </span>
                          </p>

                          <span className="text-muted " style={{ fontWeight: "bold" }}>KH. Nuridin Idris | <i> Pengasuh PPMTQNI </i> </span>
                        </div>
                      </div>
                  
                      <div className="col-md-4  px-0 pt-4 order-1 order-md-2 d-flex justify-content-center" style={{borderTopLeftRadius: "45px",borderTopRightRadius: "5px", backgroundImage: `url(${ImgBg})`, backgroundSize: "cover",borderBottomLeftRadius: "45px",borderTopRightRadius: "5px",borderBottomRightRadius: "5px", backgroundImage: `url(${ImgBg})`, backgroundSize: "cover", backgroundPosition: "center", backgroundColor: "#bac49d", }}>
                      <img
                          className=""
                          src={imgPengasuh}
                        />
</div>

                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>

    </div>
  )
}

export default SambutanPengasuh