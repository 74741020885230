import React, { useState, useEffect } from 'react';
import { List, Avatar, Drawer, Divider, Col, Row, Typography } from 'antd';
import { IoClose } from 'react-icons/io5';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios'; // Import Axios
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import { Link } from 'react-router-dom';
import "./Profile.css";

const { Text } = Typography;
const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

const axiosInstance = axios.create({
  baseURL: 'https://api-ppmtqni.mku-dev.xyz/api',
  // You can add headers or other configurations here if needed
});

const SejarahPonpes = () => {
 
  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Sejarah</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="" id="meetings">
          <div className='px-2 py-5'>

            <div className="mb-5">
              <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
               Sejarah Pondok
              </div>
              <div className="ant-result-subtitle text-center">
                <Text type="secondary" style={{ fontSize: "16px" }}>
                  di Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris.
                </Text>
              </div>

              <div className='mt-4 p-5'>
                <p></p>
              </div>
            </div>
          </div>
        </section>
      </>

      <Footer />
    </div>
  );
}

export default SejarahPonpes
