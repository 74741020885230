import React from 'react'
import { FaBackward } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const EbookRead = () => {
  return (
    <div>
        <div className="p-3">
          <Link className="btn-back" to="/e-library"><FaBackward/>Kembali</Link>
        </div>
        <div
  style={{
    position: "relative",
    paddingTop: "max(60%,324px)",
    width: "100%",
    height: 0
  }}
>

  <iframe
    style={{
      position: "absolute",
      border: "none",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0
    }}
    src="https://online.fliphtml5.com/pqeqr/pacn/"
    seamless="seamless"
    allowTransparency="true"
    allowFullScreen="true"
  />
<div className='tambal-flip'>

</div>
</div>

    </div>
  )
}

export default EbookRead