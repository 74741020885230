import React, { useState } from "react";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Pendaftaran.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import Form from "../../components/Form";
import { FaUpload } from "react-icons/fa";
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Space, Upload, Modal, notification, message, Spin } from 'antd';

const DaftarOnline = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSukses, setIsModalOpenSukses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [formData, setFormData] = useState({
    nama_lengkap: "",
    email: "",
    asal_sekolah: "",
    ttl_santri: "",
    jenjang_pendidikan: "",
    alamat_santri: "",
    pengalaman_alquran: "", // Inisialisasi sebagai string kosong
    peringkat: "",
    no_telepon: "",
    no_wa: "",
    nama_ayah: "",
    nama_ibu: "",
    pekerjaan_ayah: "",
    pekerjaan_ibu: "",
    asal_informasi: "",
    alamat_ortu: "",
    nama_wali: "",
    alamat_wali: "",
  });


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpenSukses(true)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenSukses(false)
  };

  const handleFileSelect = (e) => {
    const { name, files } = e.target;

    // Check if files are selected
    if (files.length > 0) {
      // Update selectedFile state based on input name
      setSelectedFile((prevSelectedFiles) => ({
        ...prevSelectedFiles,
        [name]: files[0], // Only take the first file if multiple selection is allowed
      }));
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      // Handling specific fields
      if (name === "nama_wali") {
        const pekerjaan = prevData.pekerjaan_wali ? `, ${prevData.pekerjaan_wali}` : "";
        updatedData = {
          ...updatedData,
          [name]: value + pekerjaan,
        };
      } else if (name === "asal_informasi") {
        // Handling checkbox "asal_informasi"
        if (e.target.checked) {
          updatedData = {
            ...updatedData,
            asal_informasi: prevData.asal_informasi
              ? `${prevData.asal_informasi},${value}`
              : value,
          };
        } else {
          updatedData = {
            ...updatedData,
            asal_informasi: prevData.asal_informasi
              .split(",")
              .filter((item) => item !== value)
              .join(","),
          };
        }
      }

      return updatedData;
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let updatedValues = [...formData.pengalaman_alquran.split(",")];

    if (checked && !updatedValues.includes(name)) {
      updatedValues.push(name);
    } else if (!checked && updatedValues.includes(name)) {
      updatedValues = updatedValues.filter((value) => value !== name);
    }

    const updatedString = updatedValues.join(",");

    setFormData((prevFormData) => {
      const pengalaman_alquran = updatedValues.length === 0
        ? prevFormData.lainnya
        : updatedString;
      return {
        ...prevFormData,
        pengalaman_alquran,
      };
    });
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Placeholder file URL
    const placeholderFileUrl = "https://example.com/placeholder.pdf";

    try {
      const formDataToSend = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key].trim() === '' ? 'Kosong' : formData[key]);
      });

      // Append selected files
      Object.keys(selectedFile).forEach((key) => {
        formDataToSend.append(key, selectedFile[key]);
      });

      // Kirim data menggunakan axios
      const response = await axios.post(
        "https://api-ppmtqni.mku-dev.xyz/api/ppdb/daftar",
        formDataToSend
      );



      // Handle API response
      if (response.status === 200) {
        // Tampilkan notifikasi sukses
        notification.success({
          message: "Sukses",
          description: "Data santri berhasil ditambahkan. Silahkan menghubungi admin kembali untuk konfirmasi",
          duration: 15
        });

      } else {
        throw new Error("Failed to add data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);

      // Tampilkan notifikasi error
      notification.error({
        message: "Error",
        description: "Gagal menambahkan data santri. Silakan coba lagi.",
      });
    } finally {
      setLoading(false); // Set state loading menjadi false setelah submit (berhasil atau gagal)
    }
  };

  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Pendaftaran Online</h2>
                <h6>Form Pendaftaran Online Calon Peserta Didik Baru</h6>
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">
          <div className="container card-form">
            <div className="row">
              <div className="col-lg-12">
                <section>
                  <div className="container">
                    <div className="row gx-4 align-items-center justify-content-between">
                      <div className="col-md-12 order-2 order-md-1 text-center">
                        <h5 className="display-8 fw-bold pb-3">
                          FORM PENDAFTARAN PPDB SMP/SMA PPMTQNI
                        </h5>
                        <span className="text-muted- pb-2">
                          Agar Pendataan PPDB berjalan dengan lancar diharapkan
                          untuk mengisi semua form yang tersedia.
                        </span>

                        <div className="text-start mt-3">
                          <span
                            className="text-muted- pb-2"
                            style={{ fontSize: "12px" }}
                          >
                            ( Tanda : <span style={{ color: "red" }}>*</span>{" "}
                            <i>Harus Diisi </i> )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <section className="container">
              <form className="form" onSubmit={handleSubmit}>
                <div className="input-box">
                  <label>
                    Nama Lengkap <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Masukan Nama Lengkap Calon Santri"
                    name="nama_lengkap"
                    id="name"
                    value={formData.nama_lengkap}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-box">
                  <label>
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Masukan Email (Jika Ada)"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="gender-box">
                  <h3>
                    Mendaftar <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div className="gender-option" >
                    <select
                      style={{ borderRadius: "8px", width: "280px", fontSize: "14px" }}
                      id="jenjang_pendidikan"
                      name="jenjang_pendidikan"
                      value={formData.jenjang_pendidikan}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-[#e0e0e0] py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="">Pilih Jenjang Pendidikan </option>
                      <option value="SMP">SMP</option>
                      <option value="SMA">SMA</option>
                    </select>
                  </div>
                </div>


                <div className="column">
                  <div className="input-box">
                    <label>
                      Sekolah / Madrasah Asal{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Masukan Sekolah / Madrasah Asal"
                      name="asal_sekolah"
                      id="asal_sekolah"
                      value={formData.asal_sekolah}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Peringkat di Sekolah / Madrasah Asal</label>
                    <input
                      type="text"
                      placeholder="Tulis peringkat yang pernah diperoleh beserta semester dan kelas"
                      name="peringkat"
                      id="peringkat"
                      value={formData.peringkat}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="gender-box">
                  <h3>
                    Jenis Kelamin <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div className="gender-option">
                    <select
                      style={{ borderRadius: "8px", width: "280px", fontSize: "14px" }}
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-sm font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="">Pilih Jenis Kelamin</option>
                      <option value="Laki - Laki">Laki- Laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                  </div>
                </div>

                <div className="input-box">
                  <label>
                    Tempat & Tanggal Lahir{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Contoh: Tegal, 17 Agustus 2010"
                    name="ttl_santri"
                    id="ttl_santri"
                    value={formData.ttl_santri}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="input-box address">
                  <label>
                    Alamat <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Contoh: Dukuh/Jalan, RT, RW , Kelurahan, Kecamatan, Kabupaten, Provinsi"
                    name="alamat_santri"
                    id="alamat_santri"
                    value={formData.alamat_santri}
                    onChange={handleInputChange}
                  />
                </div>


                <div className="gender-box">
                  <h3>
                    Pengalaman Belajar Al-Qur'an{" "}
                    <span style={{ color: "red" }}>*</span>
                  </h3>
                  <p>
                    <i>Boleh memilih lebih dari satu</i>
                  </p>
                  <p>
                    <i>Jika belum Khatam tulis juz pada pilihan lainnya</i>
                  </p>
                  <p>
                    <i>
                      Jika memiliki hafalan Al-Qur'an ditulis dalam kolom lainnya
                    </i>
                  </p>

                  <div className="gender-option-1 mt-2">
                    <div className="gender">
                      <input
                        type="checkbox"
                        id="check-iqro"
                        name="Iqro/Turutan/Sejenisnya"
                        checked={formData.pengalaman_alquran.includes("Iqro/Turutan/Sejenisnya")}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="check-iqro">Iqro/Turutan/Sejenisnya</label>
                    </div>
                    <div className="gender">
                      <input
                        type="checkbox"
                        id="check-juz-amma"
                        name="Juz Amma"
                        checked={formData.pengalaman_alquran.includes("Juz Amma")}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="check-juz-amma">Juz Amma</label>
                    </div>

                    <div className="gender">
                      <input
                        type="checkbox"
                        id="check-khatam-alquran"
                        name="Khatam Al-Qur'an (Binnadzor)"
                        checked={formData.pengalaman_alquran.includes("Khatam Al-Qur'an (Binnadzor)")}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="check-khatam-alquran">Khatam Al-Qur'an (Binnadzor)</label>
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        placeholder="Lainnya"
                        name="pengalaman_alquran"
                        value={formData.pengalaman_alquran.includes("Iqro/Turutan/Sejenisnya") ||
                          formData.pengalaman_alquran.includes("Juz Amma") ||
                          formData.pengalaman_alquran.includes("Khatam Al-Qur'an (Binnadzor)")
                          ? "" : formData.pengalaman_alquran}
                        onChange={handleInputChange}
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <div className="column mb-4">
                  <div className="input-box">
                    <label>
                      Nomor Handphone Yang Bisa Dihubungi{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Masukan Nomor Telepon Orang Tua / Wali"
                      name="no_telepon"
                      value={formData.no_telepon}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>
                      Nomor WA Yang Bisa Dihubungi{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Masukan Nomor WA"
                      name="no_wa"
                      value={formData.no_wa}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mt-3">
                    <section>
                      <div className="container">
                        <div className="row gx-4 align-items-center justify-content-between">
                          <div className="col-md-12 order-2 order-md-1 text-center">
                            <h5 className="display-8 fw-bold pb-3">
                              BIODATA ORANG TUA / WALI CALON SANTRI
                            </h5>
                            <span className="text-muted- pb-2">
                              Mohon diisi sesuai Kartu Keluarga (KK) / Dokumen
                              lainnya
                            </span>

                            <div className="text-start mt-3">
                              <span
                                className="text-muted- pb-2"
                                style={{ fontSize: "12px" }}
                              >
                                ( Tanda :{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                                <i>Harus Diisi </i> )
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div className="column ">
                  <div className="input-box">
                    <label>
                      Nama Ayah <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Masukan Nama Ayah"
                      required
                      name="nama_ayah"
                      value={formData.nama_ayah}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Pekerjaan</label>
                    <input
                      type="text"
                      placeholder="Masukan Pekerjaan Ayah"
                      required=""
                      name="pekerjaan_ayah"
                      value={formData.pekerjaan_ayah}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="column ">
                  <div className="input-box">
                    <label>
                      Nama Ibu <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Masukan Nama Ibu"
                      required=""
                      name="nama_ibu"
                      value={formData.nama_ibu}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Pekerjaan</label>
                    <input
                      type="text"
                      placeholder="Masukan Pekerjaan Ibu"
                      required=""
                      name="pekerjaan_ibu"
                      value={formData.pekerjaan_ibu}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="input-box">
                  <label>Alamat</label>
                  <input
                    type="text"
                    placeholder="Masukan Alamat Lengkap Orang Tua"
                    required=""
                    name="alamat_ortu"
                    value={formData.alamat_ortu}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="input-box">
                  <label>
                    Nama Wali Santri{" "}
                    <span>
                      {" "}
                      <i>(Jika Ada)</i>
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Masukan Nama Wali Santri"
                    required=""
                    name="nama_wali"
                    value={formData.nama_wali}
                    onChange={handleInputChange}
                  />
                </div>


                <div className="input-box mb-3">
                  <label>Alamat</label>
                  <input
                    type="text"
                    placeholder="Masukan Alamat Lengkap Wali Santri"
                    required=""
                    name="alamat_wali"
                    value={formData.alamat_wali}
                    onChange={handleInputChange}
                  />
                </div>


                <div className="row">
                  <div className="col-lg-12 mt-3">
                    <section>
                      <div className="container">
                        <div className="row gx-4 align-items-center justify-content-between">
                          <div className="col-md-12 order-2 order-md-1 text-center">
                            <h5 className="display-8 fw-bold pb-3">
                              INFORMASI DAN UPLOAD DOKUMEN
                            </h5>
                            <span className="text-muted- pb-2">
                              Mohon diupload dokumen yang dibutuhkan dibawah ini
                            </span>

                            <div className="text-start mt-3">
                              <span
                                className="text-muted- pb-2"
                                style={{ fontSize: "12px" }}
                              >
                                ( Tanda :{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                                <i>Harus Diisi </i> )
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div className="gender-box">
                  <h3>
                    Informasi Mendaftar{" "}
                    <span style={{ color: "red" }}>*</span>

                  </h3>
                  <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <>Pilih salah satu</></label>
                      </p>
                  <div className="gender-option-1 mt-2">

                    <div>

                      <div className="gender">
                        <input
                          type="checkbox"
                          id="check-medsos"
                          name="asal_informasi"
                          value="Website"
                          checked={formData.asal_informasi.includes("Website")}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="check-medsos">Website</label>
                      </div>

                      <div className="gender">
                        <input
                          type="checkbox"
                          id="check-kerabat"
                          name="asal_informasi"
                          value="Teman/Kerabat"
                          checked={formData.asal_informasi.includes("Teman/Kerabat")}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="check-kerabat">Teman/Kerabat</label>
                      </div>

                      <div className="gender">
                        <input
                          type="checkbox"
                          id="check-brosur"
                          name="asal_informasi"
                          value="Brosur"
                          checked={formData.asal_informasi.includes("Brosur")}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="check-brosur">Brosur</label>
                      </div>

                      <div className="gender">
                        <input
                          type="checkbox"
                          id="check-lainnya"
                          name="asal_informasi"
                          value="Lainnya"
                          checked={formData.asal_informasi.includes("Lainnya")}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="check-lainnya">Lainnya</label>
                      </div>

                    </div>

                    <div className="gender-box mb-4">
                      <h3>
                        Pas Foto Calon Santri
                        <span style={{ color: "red" }}>*</span>
                      </h3>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>

                      <p>
                        {" "}
                        <i>Rasio 3:4</i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>

                      <div className="">
                        <input
                          type="file"
                          name="pasfoto_url"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="pasfoto_url"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["pasfoto_url"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Pas Foto Calon Santri): {selectedFile["pasfoto_url"].name}
                          </p>
                        )}

                      </div>
                    </div>



                    <div className="gender-box mb-4">
                      <h3>
                        Akte Kelahiran Calon SantrI
                        <span style={{ color: "red" }}>*</span>
                      </h3>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>



                      <div className="">
                        <input
                          type="file"
                          name="akta_kelahiran"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                        {selectedFile["akta_kelahiran"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Akte Kelahiran Calon Santri): {selectedFile["akta_kelahiran"].name}
                          </p>
                        )}

                      </div>
                    </div>


                    <div className="gender-box mb-4">
                      <h3>
                        Kartu Keluarga (KK)
                        <span style={{ color: "red" }}>*</span>
                      </h3>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>



                      <div className="">
                        <input
                          type="file"
                          name="kartu_keluarga"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["kartu_keluarga"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Kartu Keluarga): {selectedFile["kartu_keluarga"].name}
                          </p>
                        )}

                      </div>
                    </div>


                    <div className="gender-box mb-4">
                      <h3>
                        KTP Bapak
                        <span style={{ color: "red" }}>*</span>
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>



                      <div className="">
                        <input
                          type="file"
                          name="ktp_ayah"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["ktp_ayah"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (KTP Bapak): {selectedFile["ktp_ayah"].name}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="gender-box mb-4">
                      <h3>
                        KTP Ibu
                        <span style={{ color: "red" }}>*</span>
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>

                      <div className="">
                        <input
                          type="file"
                          name="ktp_ibu"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["ktp_ibu"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (KTP Ibu): {selectedFile["ktp_ibu"].name}
                          </p>
                        )}
                      </div>
                    </div>


                    <div className="gender-box mb-4">
                      <h3>
                        Bukti Transfer Biaya Pendaftaran
                        <span style={{ color: "red" }}>*</span>
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Biaya Pendaftaran Sebesar Rp. 150.000,- </b></i>
                      </p>

                      <p style={{ fontSize: "12px" }}>

                        {" "}
                        <i> <b> No. Rekening : 7203569027</b></i>
                      </p>

                      <p style={{ fontSize: "12px" }}>

                        {" "}
                        <i> <b>Nama Rekening : Yayasan Nuridin Idis </b></i>
                      </p>

                      <p style={{ fontSize: "12px" }} className="mb-2">

                        {" "}
                        <i> <b>Bank Syariah Indonesia (BSI)</b></i>
                      </p>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format IMAGE/JPG/PNG</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 1MB</b></label>
                      </p>


                      <div className="">
                        <input
                          type="file"
                          name="bukti_transfer"
                          className="hidden"
                          accept="image/png,image/jpeg"
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["bukti_transfer"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Bukti Transfer Biaya Pendaftaran): {selectedFile["bukti_transfer"].name}
                          </p>
                        )}

                      </div>
                    </div>

                    <div className="gender-box mb-4">
                      <h3>
                        Scan Rapor SD/MI
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>SD/MI : Kelas 4 (Smt 1 & 2), 5 (Smt 1 & 2), dan 6 (Smt 1) </b></i>
                      </p>

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format PDF</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 2MB</b></label>
                      </p>


                      <div className="">
                        <input
                          type="file"
                          name="rapor_sd"
                          className="hidden"
                          accept="application/pdf" // Hanya menerima file PDF
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                        {selectedFile["rapor_sd"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Scan Rapor SD/MI): {selectedFile["rapor_sd"].name}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="gender-box mb-4">
                      <h3>
                        Scan Rapor SMP/MTS
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>SMP/MTs : Kelas 7 (Smt 1 & 2), 8 (Smt 1 & 2), dan 9 (Smt 1)</b></i>
                      </p>

                     
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format PDF</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 2MB</b></label>
                      </p>
                      <div className="">
                        <input
                          type="file"
                          name="rapor_smp"
                          className="hidden"
                          accept="application/pdf" // Hanya menerima file PDF
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {/* Tampilkan nama file di bawah teks Upload Foto Tenaga Pendidik */}
                        {selectedFile["rapor_smp"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (Scan Rapor SMP/MTS): {selectedFile["rapor_smp"].name}
                          </p>
                        )}

                      </div>
                    </div>

                    <div className="gender-box mb-4">
                      <h3>
                        SERTIFIKAT/SYAHADAH

                      </h3>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Sertifikat/Syahadah Khatam Al Qur'an dan atau Ijazah Madrasah Diniyah (Jika ada)</b></i>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <label> <b>Ukuran file tidak melebihi dari 2MB</b></label>
                      </p>

                    

                      <p style={{ fontSize: "12px" }}>
                        {" "}
                        <i> <b>Format PDF</b></i>
                      </p>
                      <div className="">
                        <input
                          type="file"
                          name="sertif_khatam"
                          className="hidden"
                          accept="application/pdf" // Hanya menerima file PDF
                          id="image"
                          onChange={handleFileSelect} // Memanggil fungsi handleFileSelect saat file dipilih
                        />

                        {selectedFile["sertif_khatam"] && (
                          <p className="mt-2 text-sm text-gray-600">
                            Selected file (SERTIFIKAT/SYAHADAH): {selectedFile["sertif_khatam"].name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div

                  className="text-center d-flex justify-content-center"
                >
                  <button
                    type="submit"
                    className="btn-submit hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-sm font-semibold text-white outline-none"
                  >
                    {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FFF' }} spin />} /> : 'Kirim Berkas Pendaftaran'}
                  </button>
                </div>
              </form>
            </section>
          </div>

          <Modal title="Apakah yakin data yang anda masukan sudah benar? " open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          </Modal>

          <Modal title="Sukses!!!" open={isModalOpenSukses} onCancel={handleCancel}>
            <p>Data anda sudah berhasil dikirim</p>
          </Modal>

          <Footer />
        </section>
      </>
    </div>
  );
};

export default DaftarOnline;
