import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import "./Profile.css"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { SlBookOpen } from "react-icons/sl";
import {PiDiamondFill  } from "react-icons/pi";


const VisiMisi = () => {
  return (
    <div>
        <>
  {/* ***** Header Area Start ***** */}
 <Header/>
  {/* ***** Header Area End ***** */}
  <section className="heading-page header-text" id="top">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Visi & Misi</h2>
          <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
        </div>
      </div>
    </div>
  </section>
  <section className="meetings-page" id="meetings">
{/* PESANTREN */}

  <div className="container">
    <h2 className="pb-3 pt-2 border-bottom mb-5">
      PONDOK PESANTREN
    </h2>
    {/*first section*/}
    <div className="row align-items-center how-it-works d-flex">
      <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
      <div className="col-9">
        <h5>Visi Pondok Pesantren</h5>
        <p>
        Membentuk Manusia Berakhlakul Karimah Berbasis Al-Qur'an
        </p>
      </div>
    </div>
    {/*path between 1-2*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner top-right" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner left-bottom" />
      </div>
    </div>
    {/*second section*/}
    <div className="row align-items-center justify-content-end how-it-works d-flex">
      <div className="col-9 text-right">
      <h5>Misi Pondok Pesantren</h5>
       <ul className='mt-2'>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Terbentuknya manusia yang mencerminkan nilai islam dalam keseharian.</li>
       <li  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan generasi yang hafal dan memiliki nilai Al-Qur'an</li>
       </ul>
      </div>
      <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
      <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
    </div>
    {/*path between 2-3*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner right-bottom" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner top-left" />
      </div>
    </div>




   
  </div>


{/* SMA */}
  <div className="container mt-5">
    <h2 className="pb-3 pt-2 border-bottom mb-5">
      SMA
    </h2>
    {/*first section*/}
    <div className="row align-items-center how-it-works d-flex">
      <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
      <div className="col-6">
        <h5>Visi SMA</h5>
        <p>
        Terbentuknya generasi Qur’ani, unggul dalam prestasi, berdaya saing global.
        </p>
      </div>
    </div>
    {/*path between 1-2*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner top-right" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner left-bottom" />
      </div>
    </div>
    {/*second section*/}
    <div className="row align-items-center justify-content-end how-it-works d-flex">
      <div className="col-9 text-right">
      <h5>Misi SMA</h5>
       <ul className='mt-2'>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membentuk manusia yang mencerminkan nilai Islam Ahlus Sunah Waljamaah yang moderat.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang hafal Al-Qur’an dan Hadits.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang berkepribadian Indonesia.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan proses pembelajaran kontekstual berbasis teknologi.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membekali peserta didik dengan Bahasa Inggris, Bahasa Arab, serta mampu berdaya saing global.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan partisipasi semua warga sekolah dalam menumbuhkan lingkungan pendidikan yang aman, nyaman dan Islami.</li>
       </ul>
      </div>
      <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
      <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
    </div>
    {/*path between 2-3*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner right-bottom" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner top-left" />
      </div>
    </div>



    
   
  </div>

{/* SMP */}
  <div className="container mt-5">
    <h2 className="pb-3 pt-2 border-bottom mb-5">
      SMP
    </h2>
    {/*first section*/}
    <div className="row align-items-center how-it-works d-flex">
      <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
      <div className="col-6">
        <h5>Visi SMP</h5>
        <p>
        Terwujudnya Sekolah Menengah Pertama Rujukan dalam Membina Generasi  Qur’ani yang Beriman, Bernalar Kritis, Berkebhinekaan, Unggul dalam Prestasi.
        </p>
      </div>
    </div>
    {/*path between 1-2*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner top-right" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner left-bottom" />
      </div>
    </div>
    {/*second section*/}
    <div className="row align-items-center justify-content-end how-it-works d-flex">
      <div className="col-9 text-right">
      <h5>Misi SMA</h5>
       <ul className='mt-2'>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Membentuk manusia yang menjadikan Al-Qur’an sebagai pedoman hidupnya, membaca, menghafal dan memahaminya isinya, serta mengamalkannya dalam seluruh aspek kehidupan.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan lulusan yang bertakwa kepada Allah SWT, berakhlak mulia.</li>
       <li className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan lulusan yang mampu melihat sesuatu dari berbagai sudut pandang dan berpikiran terbuka dengan cara memproses informasi dan gagasan, kemudian menganalis dan melakukan penalaran serta mengambil keputusan.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mengembangkan potensi peserta didik menjadi lulusan yang berprestasi dalam bidang akademik dan non akademik dengan mengikutsertakan peserta didik untuk kegiatan lomba minimal tingkat kecamatan hingga sampai tingkat internasional.</li>
       <li className='mb-1'  style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menghasilkan lulusan yang terbuka terhadap keberagaman budaya Indonesia dengan cara menghormati dan menghargai perbedaan.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Menciptakan proses pembelajaran dengan berbagai vairasi pendekatan dan metode, yang aktif, inovatif, kreatif, efektif, menyenangkan dan Islami.</li>
       <li  className='mb-1' style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"5px"}}><PiDiamondFill  style={{fontSize:"16px"}}/></span> Mewujudkan partisipasi semua warga sekolah dalam menumbuhkan lingkungan pendidikan yang aman, nyaman dan Islami</li>
       </ul>
      </div>
      <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
      <div className="circle pt-2 " style={{fontSize:"25px"}}> <SlBookOpen /></div>
      </div>
    </div>
    {/*path between 2-3*/}
    <div className="row timeline">
      <div className="col-2">
        <div className="corner right-bottom" />
      </div>
      <div className="col-8">
        <hr />
      </div>
      <div className="col-2">
        <div className="corner top-left" />
      </div>
    </div>



    
   
  </div>
  <Footer/>
  </section>
</>

    </div>
  )
}

export default VisiMisi