import React from "react"
import { Map, Marker,  Overlay } from "pigeon-maps"
import "./Map.css"
import imgLogo from "../images/ni.png"

export function MapComp() {
  return (
    <div className="wrapper-map">
    <Map height={670} defaultCenter={[-6.953255070377758, 108.98961041100729]} defaultZoom={11} >

    <Overlay anchor={[-6.953255070377758, 108.98961041100729]} offset={[50, 29]}>
        <img src={imgLogo} width={60} height={60} alt='' />
      </Overlay>

      <Marker width={50} anchor={[-6.953255070377758, 108.98961041100729]} />
    </Map>
    </div>
  )
}


