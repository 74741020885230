  import React, { useEffect, useState } from 'react';
  import "../../css/lightbox.css";
  import "../../css/owl.css";
  import "../../css/global.css";
  import { Link } from 'react-router-dom';
  import Header from '../../components/Header';
  import { useLocation } from 'react-router-dom';
  import Footer from '../../components/Footer';
  import BaseUrl from '../../apiConfig';
  import axios from 'axios';

  const AgendaMendatang = () => {
    const location = useLocation();
    const [agenda, setAgenda] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${BaseUrl}api/information/upcoming`);
          setAgenda(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('id-ID', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).format(date);
    };

    const truncateDescription = (description) => {
      if (description.length > 40) {
        return description.substring(0, 40) + '...';
      }
      return description;
    };

    return (
      <div>
        <>
          {/* ***** Header Area Start ***** */}
          <Header />
          {/* ***** Header Area End ***** */}
          <section className="heading-page header-text" id="top">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2>Agenda Mendatang</h2>
                  {/* <h6>Lis</h6> */}
                </div>
              </div>
            </div>
          </section>
          <section className="meetings-page" id="meetings">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="categories bg-light p-3" style={{ borderRadius: "8px" }}>
                    <h6 className='pb-3 mb-3' style={{ borderBottom: "1px solid #EEEEEE" }}>Arsip Agenda Mendatang</h6>
                    <ul>
                      <li className='pb-1 ' style={{ color: "#8A9A60", textDecoration: "underline" }}>
                        <a href="#">2024</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-9">
                  <section>
                    <div className="container">
                      <div className="row gx-4 align-items-center justify-content-between">
                        <div className="col-md-12 order-2 order-md-1">
                          <div className="row">
                            {agenda.map((meeting, index) => (
                              <div className="col-lg-4" key={index} >
                                <Link to="/detail-berita" >
                                  <div className="meeting-item"  >
                                    <div className="thumb">
                                      <img src={meeting.image_url} alt={meeting.title} style={{ width: "100%", height:"150px" }} />
                                    </div>
                                    <div className="down-content" style={{height:"200px"}}>
                                      <div className="date">
                                        <h6>
                                        {formatDate(meeting.date)}
                                        </h6>
                                      </div>
                                      <h4>{meeting.title}</h4>
                                      <p>
                                        {truncateDescription(meeting.description)}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </>
      </div>
    );
  };

  export default AgendaMendatang;
