import React, { useEffect, useState } from "react";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Prestasi.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Typography } from 'antd';
import BaseUrl from "../../apiConfig";
import axios from "axios";


const { Text } = Typography;

const Prestasi = () => {
  const [achievments, setAchievments] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseUrl}api/achievement`);
        setAchievments(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const galeriPrestasi = achievments.map((prestasi, index) => (
    <section className={index % 2 === 0 ? "py-5 bg-light" : "py-5"}>
      <div className="container px-5 my-5">
        <div className="row gx-5 align-items-center">
          <div className="col-lg-6">
            <img
              className="img-fluid rounded mb-5 mb-lg-0"
              src={prestasi.image_url}
              width="100%"
              height="auto"
              alt="..."
            />
          </div>
          <div className="col-lg-6">
            <h2 className="fw-bolder mb-2">{prestasi.title}</h2>
            <p className="lead fw-normal text-muted mb-3">
              {prestasi.description}
            </p>
            <p className="fw-bold mb-1">Waktu:</p>
            <p className="text-muted mb-3">{prestasi.date}</p>
            <p className="fw-bold mb-1">Tempat:</p>
            <p className="text-muted mb-0">{prestasi.location}</p>
          </div>
        </div>
      </div>
    </section>
  ));



  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Prestasi</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="" id="meetings">

          <section>
            <div className="container">
              <header className="py-5">
                <div className="container px-5">
                  <div className="row justify-content-start">
                    <div className="col-12">

                      <div className="mb-5  mt-5">
                        <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                          Prestasi Santri Sorotan Keberhasilan dan Keunggulan
                        </div>

                        <div className="ant-result-subtitle">
                          <Text type="secondary" style={{ fontSize: "16px" }}>
                            Jelajahi keberhasilan dan prestasi gemilang yang telah dicapai oleh para siswa kami di berbagai bidang, mulai dari akademik hingga ekstrakurikuler. Dari kompetisi olahraga hingga kejuaraan akademik, Galeri Prestasi Siswa kami mengabadikan momen-momen penting yang membanggakan dari perjalanan mereka dalam mencapai prestasi luar biasa. Bergabunglah dalam mengapresiasi dedikasi, kerja keras, dan bakat yang memperkaya pengalaman pendidikan di sekolah kami.

                          </Text>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </header>
              {galeriPrestasi}
            </div>
          </section>
          <Footer />
        </section>
      </>
    </div>
  );
};

export default Prestasi;
