import React from "react";
import {PiDiamondFill  } from "react-icons/pi";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
}) => {
  // Memeriksa apakah item adalah item di indeks ke-1 dan item.answer adalah sebuah array
  if (index === 1 || index === 2  && Array.isArray(item.answer)) {
    // Jika iya, maka mengubah content menjadi list <li>
    const content = (
      <ul>
        {item.answer.map((liItem, liIndex) => (
          <li key={liIndex} style={{fontSize:"14px", color:"#2a2a2a"}}><span style={{marginRight:"3px"}}><PiDiamondFill  style={{fontSize:"12px"}}/></span> {liItem}</li>
        ))}
      </ul>
    );

    return (
      <div className="faq__question" key={item.question}>
        <dt>
          <button
            aria-expanded={ariaExpanded}
            aria-controls={`faq${index + 1}_desc`}
            data-qa="faq__question-button"
            className={`faq__question-button ${fontWeightBold}`}
            onClick={onClick}
          >
            {item.question}
          </button>
        </dt>
        <dd>
          <div // Menggunakan div sebagai wadah, bukan <p>
            id={`faq${index + 1}_desc`}
            data-qa="faq__desc"
            className={`faq__desc ${showDescription}`}
          >
            {content}
          </div>
        </dd>
      </div>
    );
  }

  // Jika bukan item di indeks ke-1 atau item.answer tidak sebuah array, render seperti biasa
  return (
    <div className="faq__question" key={item.question}>
      <dt>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`faq${index + 1}_desc`}
          data-qa="faq__question-button"
          className={`faq__question-button ${fontWeightBold}`}
          onClick={onClick}
        >
          {item.question}
        </button>
      </dt>
      <dd>
        <p
          id={`faq${index + 1}_desc`}
          data-qa="faq__desc"
          className={`faq__desc ${showDescription}`}
        >
          {item.answer}
        </p>
      </dd>
    </div>
  );
};

export default AccordionItem;
