import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import "./Profile.css"
import imgStruktur from "../../images/Struktur.jpg"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Image } from 'antd'

const StrukturOrganisasi = () => {
  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Struktur Organisasi</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">
          <div className='container outer-wrapper'>
                  <div className="struktur-wrapper">
                    <Image
                      src={imgStruktur}
                    />
                  </div>
          </div>
             
      
          <Footer />
        </section>
      </>

    </div>
  )
}

export default StrukturOrganisasi