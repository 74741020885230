import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import "./Ekstrakulikuler.css"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import {  Typography } from 'antd';
import { IoEyeOutline, IoInformationCircle, IoInformationOutline, IoListOutline } from 'react-icons/io5'
import { MdDetails } from 'react-icons/md'


const { Text } = Typography;

const Ekstrakulikuler = () => {

const { Meta } = Card;

  return (
    <div>
        <>
  {/* ***** Header Area Start ***** */}
 <Header/>
  {/* ***** Header Area End ***** */}
  <section className="heading-page header-text" id="top">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Ekstrakulikuler</h2>
          <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
        </div>
      </div>
    </div>
  </section>
  <section className="meetings-page" id="meetings">
  <section className="py-5">
  <div className="container">
    <div className="row justify-content-center text-center mb-2 mb-lg-4">
    <div className="mb-5  mt-5">
              <div className="mb-3 text-center" style={{fontSize:"20px", textTransform:"uppercase"}}>
         Aktifitas Luar Kelas Santri
              </div>

              <div className="ant-result-subtitle">
                <Text type="secondary" style={{fontSize:"16px"}}>
                Menjelajahi Dunia Luar Kelas Menggali Potensi dan Kreativitas Santri
              
                </Text>
              </div>
            </div>
    </div>
    <div className="row py-3 align-items-center">
      <div className="col-md-6 mt-md-0 mt-4">
        <div className="mb-5 mb-lg-3">
          <img
            className="img-fluid"
            src="https://res.cloudinary.com/dyhunlqi0/image/upload/v1711727640/PPTQNI/PENDIDIK/IMG_6307_rdzrex.jpg"
          />
        </div>
      </div>
      <div className="col-md-6 ps-md-5">
        <div className="mb-5 mb-lg-3">
        <div className="mb-3 text-center" style={{fontSize:"18px", textTransform:"uppercase"}}>
        Lebih dari Sekadar Ruang Kelas
              </div>
          <Text type="secondary" style={{fontSize:"16px"}}>
          Pendidikan bukan hanya terikat pada tembok kelas. Kami percaya bahwa setiap santri memiliki potensi luar biasa yang perlu digali dan dikembangkan melalui berbagai kegiatan di luar kelas.
              
                </Text>
        </div>
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-lg-3 col-md-6">
        <div className="mb-3 mb-lg-0">
        <Card
    style={{
      width: 300,
      height:350,
    }}
    cover={
      <img
        alt="example"
        src="https://images.unsplash.com/photo-1622137879013-beaca5144a4b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        height="200px"
     
        />
    }
    actions={[
   
      <IoListOutline key="edit" />,
    ]}
  >
    <Meta
     
      title="Bahasa Arab"
      description="This is the description"
    />
  </Card>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3 mb-lg-0">


        <Card
    style={{
      width: 300,
      height:350,

    }}
    cover={
      <img
        alt="example"
        src="https://images.unsplash.com/photo-1601176682258-172d20729a0c?q=80&w=2018&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D        "
        height="200px"

     
        />
    }
    actions={[
      <IoListOutline key="edit" />,

    ]}
  >
    <Meta
   
      title="Olahraga"
      description="This is the description"
    />
  </Card>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3 mb-lg-0">
        <Card
    style={{
      width: 300,
      height:350,

    }}
    cover={
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        height="200px"

      />
    }
    actions={[
      <IoListOutline key="edit" />,

    ]}
  >
    <Meta
    
      title="Tilawatil Qur'an"
      description="This is the description"
    />
  </Card>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3 mb-lg-0">
        <Card
    style={{
      width: 300,
      height:350,

    }}
    cover={
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        height="200px"


      />
    }
    actions={[
      <IoListOutline key="edit" />,

    ]}
  >
    <Meta
     
      title="Hadroh"
      description="This is the description"
    />
  </Card>
        </div>
      </div>
    </div>
  </div>
</section>


  <Footer/>
  </section>
</>

    </div>
  )
}

export default Ekstrakulikuler