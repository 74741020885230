import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Galeri.css";
import { Image } from "antd";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Typography } from 'antd';
import BaseUrl from "../../apiConfig";

const { Text } = Typography;

const Galeri = () => {
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseUrl}api/gallery/all`);
        setGalleryData(response.data.data); // Ubah ke response.data.data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Galeri</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">
          <div className="container">
            <div className="mb-5 mt-5">
              <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                Kegiatan Santri dan Inspirasi
              </div>
              <div className="ant-result-subtitle">
                <Text type="secondary" style={{ fontSize: "16px" }}>
                  Saksikan momen-momen inspiratif yang menghadirkan sorotan gemilang dari perjalanan kegiatan santri. Momen keberhasilan, dedikasi, dan semangat dalam proses belajar Al-Qur'an.
                </Text>
              </div>
            </div>

            <div className="row">
              {galleryData.map((item, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-3">
                  <Image src={item.img_url} />
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </section>
      </>
    </div>
  );
};

export default Galeri;
