import React from "react";
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Prestasi.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Typography } from 'antd';
import { FaReadme } from "react-icons/fa";



const { Text } = Typography;


const KurikulumPesantren = () => {



    return (
        <div>
            <>
                {/* ***** Header Area Start ***** */}
                <Header />
                {/* ***** Header Area End ***** */}
                <section className="heading-page header-text" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Kurikulum Pesantren</h2>
                                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="" id="meetings">

                    <section>
                        <div className="container">
                            <header className="py-5">
                                <div className="container px-5">
                                    <div className="row justify-content-start">
                                        <div className="col-12">

                                            <div className="mb-3 mt-5">
                                                <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                                                    TAHAPAN MENGHAFAL AL-QUR'AN
                                                </div>

                                                <div className="ant-result-subtitle">
                                                    <Text type="secondary" style={{ fontSize: "16px" }}>
                                                    Tahapan-tahapan sistematis yang dirancang untuk mempermudah proses menghafal Al-Qur'an, meningkatkan pemahaman, dan memperkuat hafalan Santri.
                                                    </Text>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </header>
                            <section className="py-5">
  <div className="container">
    
    <div className="row g-xl-5 py-4">
      <div className="col-md-4">
        <div className="mx-0 mx-xl-3 py-3 py-md-0 text-center">
          <div className="text-primary mb-md-4 mb-2">
            <svg
              className="bi bi-quote"
              fill="#8E9A5E"
              height={48}
              viewBox="0 0 16 16"
              width={48}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
            </svg>
          </div>
          <p className="mb-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </p>
       
          <h2 className="mt-4 text-muted">1</h2>
        </div>
      </div>
      <div className="col-md-4">
        <div className="mx-0 mx-xl-3 py-3 py-md-0 text-center">
          <div className="text-primary mb-md-4 mb-2">
            <svg
              className="bi bi-quote"
              fill="#8E9A5E"
              height={48}
              viewBox="0 0 16 16"
              width={48}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
            </svg>
          </div>
          <p className="mb-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </p>
       
          <h2 className="mt-4 text-muted">2</h2>
        </div>
      </div>
      <div className="col-md-4">
        <div className="mx-0 mx-xl-3 py-3 py-md-0 text-center">
          <div className="text-primary mb-md-4 mb-2">
            <svg
              className="bi bi-quote"
              fill="#8E9A5E"
              height={48}
              viewBox="0 0 16 16"
              width={48}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
            </svg>
          </div>
          <p className="mb-4">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </p>
         
       
          <h2 className="mt-4 text-muted">3</h2>
        </div>
      </div>
    </div>
  </div>
</section>

                         

                        </div>
                    </section>

                    <Footer />
                </section>
            </>
        </div>
    );
};

export default KurikulumPesantren;
