import React, { useState, useEffect } from 'react';
import { List, Avatar, Drawer, Divider, Col, Row, Typography } from 'antd';
import { IoClose } from 'react-icons/io5';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios'; // Import Axios
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import { Link } from 'react-router-dom';
import "./Profile.css";

const { Text } = Typography;
const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

const axiosInstance = axios.create({
  baseURL: 'https://api-ppmtqni.mku-dev.xyz/api',
  // You can add headers or other configurations here if needed
});

const DaftarPendidik = () => {
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/teacher/all');
      setTeachers(response.data.data); // Assuming the response contains an array of teachers
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error gracefully, e.g., show an error message
    }
  };

  const showDrawer = (item) => {
    setSelectedItem(item);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Tenaga Pendidik</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="" id="meetings">
          <div className='px-2 py-5'>

            <div className="mb-5">
              <div className="mb-3 text-center" style={{ fontSize: "20px", textTransform: "uppercase" }}>
                Daftar Tenaga Pendidik Aktif
              </div>
              <div className="ant-result-subtitle text-center">
                <Text type="secondary" style={{ fontSize: "16px" }}>
                  di Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris.
                </Text>
              </div>

              <div className='mt-4 p-5'>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 5,
                    xxl: 5,
                  }}
                  dataSource={teachers} // Use teachers state here
                  className='py-3 '
                  renderItem={item => (
                    <List.Item
                      key={item.key} // Assuming item has a unique key
                      actions={[
                        <div className='my-2 color-warning text-center ms-5'>
                          <a onClick={() => showDrawer(item)} key={`a-${item.key}`}>
                            Lihat Profil
                          </a>
                        </div>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar shape="square" size={70} src={item.image_url} style={{ objectFit: 'cover', objectPosition: 'top' }} />
                        }
                        title={<h6 href="https://ant.design/index-cn">{item.name}</h6>}
                        description={item.position}
                      />
                    </List.Item>
                  )}
                />
                <Drawer
                  width="60vw"
                  placement="right"
                  closable={false}
                  onClose={onClose}
                  open={visible}
                  className="responsive-drawer"
                >
                  <div className='mt-4'>
                    <IoClose style={{ fontSize: "22px", cursor: "pointer", color: "grey" }} onClick={() => onClose()} />
                  </div>
                  <div>
                    <Divider orientation="center">Detail Profil</Divider>

                    <div>

                      {selectedItem && (
                        <>
                          <p style={pStyle}>Personal</p>
                          <Row>
                            <Col span={12}>
                              <Avatar className="mb-3" shape="square" size={230} src={selectedItem.image_url} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <DescriptionItem title="Nama" content={selectedItem.name} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="Alamat" content={selectedItem.address} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem
                                title="Jenis Kelamin"
                                content={selectedItem.gender}
                              />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="Jabatan" content={selectedItem.position} />
                            </Col>
                          </Row>
                          <Divider />
                          <p style={pStyle}>Akademik</p>
                          <Row>
                            <Col span={12}>
                              <DescriptionItem title="Formal" content={selectedItem.formal_education} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="Non Formal" content={selectedItem.informal_education} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="Prodi" content={selectedItem.major} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="Tahun Lulus" content={selectedItem.graduate_year} />
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      )}
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </section>
      </>

      <Footer />
    </div>
  );
}

export default DaftarPendidik;
